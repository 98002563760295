<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        姓名：
        <el-input placeholder="请输入姓名" v-model="cName" clearable></el-input>
      </div>
      <div>
        用户名：
        <el-input placeholder="请输入用户名" v-model="cLoginname" clearable></el-input>
      </div>
      <div>
        性别：
        <el-select v-model="cGender" placeholder="全部" clearable>
          <el-option label="女" :value="0"></el-option>
          <el-option label="男" :value="1"></el-option>
        </el-select>
      </div>
      <div>
        联系方式：
        <el-input placeholder="请输入联系方式" v-model="cPhone" clearable></el-input>
      </div>
      <div>
        账号状态：
        <el-select v-model="cDelFlag" placeholder="全部" clearable>
          <el-option label="启用" :value="0"></el-option>
          <el-option label="禁用" :value="1"></el-option>
        </el-select>
      </div>
      <span>
        <el-button v-if="$has('viewAdmin')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-button v-if="$has('addAdmin')" type="warning" size="mini" icon="el-icon-plus" @click="adds(0)">新 增</el-button>
      </span>
    </div>
    <div class="cont">
      <template>
        <el-table :data="tableData" @row-click="rows" height="calc(100% - 40px)">
          <el-table-column prop="areaName" label="区域名称" width="101"></el-table-column>
          <el-table-column prop="organizationName" label="所属机构" show-overflow-tooltip></el-table-column>
          <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
          <el-table-column prop="loginname" label="用户名"></el-table-column>
          <el-table-column prop="gender" label="性别" :formatter="gender"></el-table-column>
          <el-table-column prop="phone" label="联系方式"></el-table-column>
          <el-table-column prop="delFlag" label="账号状态" :formatter="delFlag"></el-table-column>
          <el-table-column prop="roleName" label="角色"></el-table-column>
          <el-table-column prop="createDt" label="创建时间" width="150"></el-table-column>
          <el-table-column prop="updateDt" label="更新时间" width="150"></el-table-column>
          <el-table-column label="操作" width="240">
            <template slot-scope="scope">
              <el-button v-if="$has('editAdmin')" type="warning" size="small" @click="adds(1)">编 辑</el-button>
              <el-button v-if="$has('editAdminPassword')" type="primary" size="small" @click="modifyVisible = true">修改密码</el-button>
              <el-button v-if="$has('editAdminUseFlag')" :type="scope.row.delFlag == 0 ? 'danger' : 'success'" size="small" @click="changeFlag(scope.row)">{{ scope.row.delFlag | delFlagFliter }}</el-button>
            </template>
          </el-table-column>
          <div slot="empty" class="empty">
            <img src="../../public/home/noData.svg" width="320" alt="" />
            <span>暂无数据</span>
          </div>
        </el-table>
      </template>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 模态框 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center @closed="cancles">
        <div class="dialogsC">
          <div class="newDialog">
            <div>
              <span>区域名称：</span>
              <el-select filterable v-model="paramsObj.areaId" @change="cxjgmc(paramsObj.areaId)" placeholder="全部" clearable>
                <el-option :label="item.name" v-for="(item, index) in cSuperiorArr" :key="index" :value="item.id"></el-option>
              </el-select>
            </div>
            <div>
              <span>所属机构：</span>
              <el-select filterable v-model="paramsObj.organizationId" placeholder="全部" clearable>
                <el-option :label="item.name" v-for="(item, index) in jgmcArr" :key="index" :value="item.id"></el-option>
              </el-select>
            </div>
            <div>
              <span>姓名：</span>
              <el-input placeholder="请输入姓名" v-model="paramsObj.name" clearable></el-input>
            </div>
            <div>
              <span>用户名：</span>
              <el-input placeholder="请输入用户名" v-model="paramsObj.loginname" clearable></el-input>
            </div>
            <div>
              <span>性别：</span>
              <el-select v-model="paramsObj.gender" placeholder="全部" clearable>
                <el-option label="女" :value="0"></el-option>
                <el-option label="男" :value="1"></el-option>
              </el-select>
            </div>
            <div>
              <span>联系方式：</span>
              <el-input placeholder="请输入联系方式" v-model="paramsObj.phone" clearable></el-input>
            </div>
            <div>
              <span>角色类型：</span>
              <el-select filterable v-model="paramsObj.roleId" placeholder="全部" clearable>
                <el-option :label="item.roleName" v-for="(item, index) in roleArr" :key="index" :value="item.id"></el-option>
              </el-select>
            </div>
            <div style="color: red">说明：初始密码为用户名@今年年份，如需修改可登陆后进行密码修改操作</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="dialogsAdd">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改密码 -->
      <el-dialog :title="modifyTitle" :visible.sync="modifyVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center @closed="cancles">
        <div class="dialogsC">
          <div class="newDialog">
            <div v-if="modifyFlag">
              <span>请输入新密码：</span>
              <el-input show-password placeholder="请输入新密码" v-model="password" clearable></el-input>
            </div>
            <div v-if="modifyFlag">
              <span>请再次输入新密码：</span>
              <el-input show-password placeholder="请再次输入新密码" v-model="passwordEnd" clearable></el-input>
            </div>
            <div v-if="!modifyFlag" style="font-size: 24px">是否{{ modifyContent }}此管理员？</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="passwordModify">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { organizationQueryWithPage, roleQuery, adminQuery, adminSave, adminUpdate, adminChangeUseFlag, adminChangePassword } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //列表
      cgxqy: '', // 查询区域名称
      cjgmc: '', // 查询机构名称
      cSuperiorArr: [], // 查询区域名称数组
      jgmcArr: '', // 机构名称数组
      cName: '', //查询姓名
      cGender: '', //查询性别
      cPhone: '', //查询联系方式
      cLoginname: '', //查询用户名
      cDelFlag: '', //查询标记
      dialogTitle: '新增管理员', //模态框标题
      dialogVisible: false, //模态框状态
      modifyVisible: false, //删除模态框状态
      paramsObj: {}, //模态框字段
      type: '', //新增修改类型
      roleArr: [], //角色数组
      password: '', //新密码
      passwordEnd: '', //再次输入新密码
      user: '', //用户信息
      modifyTitle: '修改密码', //修改标题
      modifyFlag: true, //判断修改密码还是点击启用禁用
      modifyContent: '启用', //启用禁用内容
    }
  },
  methods: {
    // 拿值区域和机构
    getData(msg) {
      this.cgxqy = msg[0]
      this.cjgmc = msg[1]
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        name: this.cName,
        gender: this.cGender,
        phone: this.cPhone,
        loginname: this.cLoginname,
        delFlag: this.cDelFlag,
      }
      await adminQuery(params)
        .then((data) => {
          if (data) {
            this.tableData = data.result.records
            this.totals = data.result.total
          }
        })
        .catch(() => {})
      loading.close()
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 获取每列数据
    rows(rows) {
      this.paramsObj = Object.assign({}, rows)
    },
    // 模态框显示
    adds(type) {
      this.paramsObj = {}
      this.type = type
      this.dialogVisible = true
      if (type == 0) {
        this.dialogTitle = '新增管理员'
        var id = 0
      } else if (type == 1) {
        this.dialogTitle = '编辑管理员'
        var id = ''
        setTimeout(() => {
          let params = {
            areaId: this.paramsObj.areaId,
            pageNo: 1,
            pageSize: 10000,
          }
          organizationQueryWithPage(params).then((data) => {
            this.jgmcArr = data.result.records
          })
        }, 10)
      }
      this.roleList(id)
    },
    // 新增，修改
    async dialogsAdd() {
      if (this.type == 0) {
        this.paramsObj.delFlag = 0
        await adminSave(qs.stringify(this.paramsObj)).then((data) => {
          if (data != undefined) {
            this.initData(data)
          }
        })
      } else if (this.type == 1) {
        await adminUpdate(qs.stringify(this.paramsObj)).then((data) => {
          if (data != undefined) {
            this.initData(data)
          }
        })
      }
    },
    // 取消
    cancles() {
      this.paramsObj = {}
      this.dialogVisible = false
      this.modifyVisible = false
      this.jgmcArr = []
      this.password = ''
      this.passwordEnd = ''
      this.modifyFlag = true
      this.modifyTitle = '修改密码'
    },
    // 查询机构名称
    cxjgmc(areaId) {
      this.cjgmc = ''
      this.jgmcArr = []
      if (this.paramsObj.organizationId != undefined) {
        this.paramsObj.organizationId = ''
      }
      let params = {
        areaId,
        pageNo: 1,
        pageSize: 10000,
      }
      organizationQueryWithPage(params).then((data) => {
        this.jgmcArr = data.result.records
      })
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.jgmcArr = []
      this.cName = ''
      this.cGender = ''
      this.cPhone = ''
      this.cLoginname = ''
      this.cDelFlag = ''
      this.searchs(1, 10)
    },
    // 转换
    gender(row) {
      if (row.gender == 0) {
        return '女'
      } else if (row.gender == 1) {
        return '男'
      }
    },
    delFlag(row) {
      if (row.delFlag == 0) {
        return '启用'
      } else if (row.delFlag == 1) {
        return '禁用'
      }
    },
    //角色类型
    roleList(deleted) {
      let params = {
        deleted,
        pageNo: 1,
        pageSize: 1000,
      }
      roleQuery(params).then((data) => {
        this.roleArr = data.result.records
      })
    },
    // 修改密码
    passwordModify() {
      if (!this.modifyFlag) {
        // console.log(this.paramsObj);
        let params = { id: this.paramsObj.id }
        if (this.paramsObj.delFlag == 0) {
          params.delFlag = 1
        } else if (this.paramsObj.delFlag == 1) {
          params.delFlag = 0
        }
        adminChangeUseFlag(params).then((data) => {
          if (data != undefined) {
            this.initData(data)
          }
        })
      } else {
        if (this.password != this.passwordEnd) {
          this.$message({
            type: 'error',
            message: '两次密码输入不一致，请重试！',
          })
        } else {
          let params = {
            id: this.paramsObj.id,
            password: this.password,
          }
          adminChangePassword(params).then((data) => {
            if (data != undefined) {
              this.initData(data)
            }
          })
        }
      }
    },
    // 启用禁用
    changeFlag(row) {
      if (row.delFlag == 0) {
        if (this.user.id == row.id) {
          this.$message({
            type: 'error',
            message: '不可禁用自己!',
          })
          return
        }
        this.modifyContent = '禁用'
      } else if (row.delFlag == 1) {
        this.modifyContent = '启用'
      }
      this.modifyVisible = true
      this.modifyFlag = false
      this.modifyTitle = '修改管理员状态'
    },
    // 弹框清空重查
    initData(data) {
      if (data) {
        this.$message({
          type: 'success',
          message: data.message,
        })
      }
      this.cancles()
      this.searchs(1, 10)
      this.modifyFlag = true
      this.modifyTitle = '修改密码'
    },
  },
  filters: {
    delFlagFliter(delFlag) {
      switch (delFlag) {
        case 1:
          return '启 用'
        case 0:
          return '禁 用'
      }
    },
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.cgxqy = this.user.areaId
    this.cjgmc = this.user.organizationId
    this.cSuperiorArr = JSON.parse(sessionStorage.getItem('gxqylist')) //区域名称数组
    this.searchs(1, 10)
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
}
</style>
